import BurgerOpen from '@/images/icons/burger-open.svg';
import BurgerClose from '@/images/icons/close.svg';

interface MobileNavigationTriggerProps {
    mobileNavigationVisible: boolean;
    toggleMobileNavigation: (open: boolean) => void;
}

export default function MobileNavigationTrigger({mobileNavigationVisible, toggleMobileNavigation}: MobileNavigationTriggerProps) {
    return (
        <button
            type="button"
            className="h-full w-14 sm:w-16 relative z-40 bg-primary rounded-br-lg flex items-center justify-center"
            onClick={() => toggleMobileNavigation(!mobileNavigationVisible)}>
            {mobileNavigationVisible ? (
                <BurgerClose className="w-6 h-6 burger-button"/>
            ) : (
                <BurgerOpen className="w-6 h-6 burger-button"/>
            )}
        </button>
    )
}
