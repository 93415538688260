import React, {ChangeEvent, FormEvent, MouseEvent, useEffect, useRef, useState} from 'react';
import CloseIcon from '@/images/icons/close.svg';
import SearchIcon from '@/images/icons/search.svg';
import DeleteIcon from '@/images/icons/delete-put-back.svg';
import {AnimatePresence, motion} from 'framer-motion';
import useTranslations from '@/hooks/translations';
import {getFullTextItems, getFullTextItemsGrouped, getFulltextSuggestions} from '@/api/search';
import {GroupedSearchItem, SearchAggregationValue, SearchHits} from '@/types/search';
import ResultsGrouped from '@/app/layout/search/ResultsGrouped';
import Loader from '@/app/components/ui/Loader';
import Results from '@/app/layout/search/Results';
import MobileNavigationTrigger from '@/app/navigation/mobile/MobileNavigationTrigger';

interface SearchProps {
    searchOverlayVisible: boolean;
    toggleSearchOverlay: (open: boolean) => void;
    mobileNavigationVisible: boolean;
    toggleMobileNavigation: (open: boolean) => void;
}

export default function Search({toggleSearchOverlay, searchOverlayVisible, mobileNavigationVisible, toggleMobileNavigation}: SearchProps) {
    const [resetButtonVisible, setResetButtonVisible] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchSubmitted, setSearchSubmitted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [groupedResults, setGroupedResults] = useState<GroupedSearchItem[]>([]);
    const [results, setResults] = useState<SearchHits[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const [subTypeAggregations, setSubTypeAggregations] = useState<SearchAggregationValue[]>([]);
    const [activeSubType, setActiveSubType] = useState<string | null | undefined>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const {tc} = useTranslations();


    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeOverlay();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (searchOverlayVisible) {
            searchInputRef.current?.focus();
        }
    }, [searchOverlayVisible]);

    useEffect(() => {
        if (searchQuery.length > 0) {
            setResetButtonVisible(true);
        } else {
            setResetButtonVisible(false);
        }
    }, [searchQuery]);

    const search = async () => {

        if (!searchOverlayVisible) {
            return;
        }

        setIsLoading(true);
        let result;

        if (activeSubType) {
            result = await getFullTextItems(searchQuery, activeSubType, 1);
            setResults(result.hits);
            setGroupedResults([]);
        } else {
            result = await getFullTextItemsGrouped(searchQuery);
            setGroupedResults(result.hits);
            setResults([]);
        }

        setSearchSubmitted(true);
        setTotalCount(result.totalCount);
        setSubTypeAggregations(result.aggregations.find(a => a.key === 'sub_types')?.values ?? []);
        setIsLoading(false);
        setSuggestions([]);
        setSuggestionsVisible(false);
    }

    const onChangeSearchQuery = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);

        if (searchQuery.length < 3) {
            setSuggestions([]);
            return;
        }

        const suggestions = getFulltextSuggestions(searchQuery);
        suggestions.then(res => {
            setSuggestionsVisible(true);
            setSuggestions(res.suggestions.slice(0, 5));
        });
    }

    const onClickSuggestion = (e: MouseEvent, suggestion: string) => {
        e.stopPropagation();
        setSearchQuery(suggestion);
        setSuggestionsVisible(false);
        search().then();
    }

    const onClickSearchInput = (e: MouseEvent) => {
        e.stopPropagation();
        setSuggestionsVisible(true);
    }

    const closeOverlay = () => {
        resetSearch();
        toggleSearchOverlay(false);
    }

    const resetSearch = () => {
        setSearchQuery('');
        setSuggestions([]);
        setSuggestionsVisible(false);
        setSearchSubmitted(false);
        setTotalCount(0);
        setActiveSubType(undefined);
        setResults([]);
    }

    const highlightText = (text: string) => {
        const regex = new RegExp(`(${searchQuery})`, 'gi');

        if (text.match(regex)) {
            const parts = text.split(regex);
            return parts
                .map((part, i) =>
                    part.match(regex)
                        ? <span key={i} className="font-bold">{part}</span>
                        : <span key={i}>{part}</span>
                );
        }

        return <span>{text}</span>
    }

    const onFilterSubType = (subType: string | null) => {
        if (activeSubType === subType) {
            setActiveSubType(null);
        } else {
            setActiveSubType(subType);
        }
    }

    useEffect(() => {
        if (activeSubType !== undefined) {
            search();
        }
    }, [activeSubType]);

    return (
        <AnimatePresence>
            {searchOverlayVisible && (
                <div className="relative z-40" onClick={() => setSuggestionsVisible(false)}>
                    <motion.div
                        className="relative z-50"
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 0.3, ease: 'easeOut'}}}
                        exit={{opacity: 0, transition: {duration: 0.2, ease: 'easeOut'}}}
                    >
                        <form onSubmit={(e: FormEvent) => {
                            e.preventDefault();
                            search().then();
                        }}
                              className="bg-white shadow-[0_2px_8px_0_rgba(0,0,0,0.16)] rounded-b-lg overflow-hidden z-50 absolute w-full left-0 right-0">
                            <div className="h-14 sm:h-16 lg:h-20 flex justify-between items-center relative">
                                <div className="relative flex items-start h-full grow">
                                    <button
                                        type="button"
                                        className={`text-grey-300 hover:text-blue-200 h-full transition ${isLoading ? 'opacity-0' : 'opacity-100'} transition duration-200`}
                                        onClick={() => search()}
                                        disabled={isLoading}
                                    >
                                        <SearchIcon className="w-6 h-auto mx-auto my-auto ml-4 sm:ml-10"/>
                                    </button>
                                    <Loader isLoading={isLoading}
                                            wrapperClass="absolute left-4 sm:left-10 top-[15px] sm:top-[19px] lg:top-[26px]"/>
                                    <input
                                        ref={searchInputRef}
                                        value={searchQuery}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeSearchQuery(e)}
                                        onClick={(e: MouseEvent) => onClickSearchInput(e)}
                                        className="w-full h-full p-3 text-grey-500 placeholder-grey-400 focus:border-none focus:outline-none"
                                        type="text"
                                        placeholder={tc('app.search.input.placeholder')}/>
                                </div>
                                <div className="block w-14 min-w-14 sm:w-16 lg:w-20 h-full">
                                    {resetButtonVisible && (
                                        <button
                                            onClick={() => {
                                                resetSearch();
                                                searchInputRef.current?.focus();
                                            }}
                                            type="button"
                                            className="h-full w-full flex text-blue-100 hover:text-blue-200 transition">
                                            <DeleteIcon className="w-6 h-auto mx-auto my-auto"/>
                                        </button>
                                    )}
                                </div>
                                <div className="w-14 min-w-14 sm:w-16 lg:w-20 h-full">
                                    <button
                                        onClick={closeOverlay}
                                        type="button"
                                        className="bg-grey-125 h-full w-full flex text-blue-100 hover:text-blue-200 transition border-l border-grey-175">
                                        <CloseIcon className="w-6 h-auto mx-auto my-auto"/>
                                    </button>
                                </div>
                                <div className="xs:hidden ty:block w-14 min-w-14 h-full sm:w-16 lg:w-20 lg:hidden">
                                    <MobileNavigationTrigger mobileNavigationVisible={mobileNavigationVisible}
                                                             toggleMobileNavigation={toggleMobileNavigation} />
                                </div>
                            </div>
                        </form>
                        {suggestions.length > 0 && suggestionsVisible && (
                            <ul
                                className="mt-2 py-2 bg-white shadow-[0_2px_8px_0_rgba(0,0,0,0.16)] rounded-lg overflow-hidden absolute top-[var(--header-height)] w-full">
                                {suggestions.map((s, i) => (
                                    <li key={i}>
                                        <button onClick={(e: MouseEvent) => onClickSuggestion(e, s)}
                                                className="px-4 py-3 hover:bg-grey-150 transition w-full h-full text-left"
                                                type="button">
                                            {highlightText(s)}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </motion.div>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {duration: 0.5, ease: 'easeOut'}}}
                        exit={{opacity: 0, transition: {duration: 0.3, ease: 'easeOut'}}}
                    >
                        <div className="fixed top-0 left-0 w-screen h-full z-40 overflow-y-scroll">

                            {totalCount < 1 && searchSubmitted && (
                                <div className="absolute w-full h-full">
                                    <div className="container px-12 lg:px-4 py-24 lg:py-32">
                                        <span
                                            className="text-sm text-white"
                                            dangerouslySetInnerHTML={{
                                                __html: tc('app.search.no_results.text')
                                                    .replace('%term%', `<strong>${searchQuery}</strong>`)
                                            }}
                                        ></span>
                                    </div>
                                </div>
                            )}

                            <AnimatePresence>
                                {totalCount > 0 && searchSubmitted && (
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1, transition: {duration: 0.5, ease: 'easeOut'}}}
                                        exit={{opacity: 0, transition: {duration: 0.3, ease: 'easeOut'}}}
                                    >
                                        <div className="absolute w-screen min-h-full bg-white">
                                            <div className="container py-24 lg:py-32">

                                                <span className="text-sm text-grey-400"
                                                      dangerouslySetInnerHTML={{
                                                          __html: tc('app.search.results.text')
                                                              .replace('%count%', totalCount.toString())
                                                              .replace('%term%', `<strong>${searchQuery}</strong>`)
                                                      }}></span>

                                                {subTypeAggregations.length > 0 && (
                                                    <div
                                                        className="flex flex-nowrap gap-2 my-8 w-full overflow-x-scroll no-scrollbar">
                                                        <button
                                                            className={`filter-chip ${!activeSubType ? 'filter-chip-primary' : 'filter-chip-secondary'}`}
                                                            disabled={isLoading}
                                                            onClick={() => onFilterSubType(null)}>
                                                            <span>{tc('app.search.filter.all')}</span>
                                                        </button>
                                                        {subTypeAggregations.map((a, i) => (
                                                            <button
                                                                key={i}
                                                                className={`filter-chip ${activeSubType === a.value ? 'filter-chip-primary' : 'filter-chip-secondary'}`}
                                                                disabled={isLoading}
                                                                onClick={() => onFilterSubType(a.value.toString())}>
                                                                <span>{a.label}</span>
                                                            </button>
                                                        ))}
                                                    </div>
                                                )}

                                                <div
                                                    className={`${isLoading ? 'opacity-0 pointer-events-none' : 'opacity-100'} transition duration-200`}>
                                                    {activeSubType ? (
                                                        <Results
                                                            initialHits={results}
                                                            isInGroup={false}
                                                            searchQuery={searchQuery}
                                                            itemCount={totalCount}
                                                            label={subTypeAggregations.find(a => a.value === activeSubType)?.label ?? ''}
                                                            subType={activeSubType}
                                                        />
                                                    ) : (
                                                        <ResultsGrouped results={groupedResults} searchQuery={searchQuery}/>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    )
}
