import {INavigation} from '@/app/navigation/Navigation.types';
import React, {useEffect} from 'react';
import {usePathname, useSearchParams} from 'next/navigation';

import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import MobileNavigationPanel from '@/app/navigation/mobile/MobileNavigationPanel';
import {I18nActiveLanguages} from '@/types/i18n';


interface MobileNavigationProps {
    pages: Array<INavigation>;
    languages: I18nActiveLanguages[];
    productOverviewPage?: string;
    mobileNavigationVisible: boolean;
    toggleMobileNavigation: (open: boolean) => void;
}

export default function MobileNavigation({pages, languages, productOverviewPage, mobileNavigationVisible, toggleMobileNavigation}: MobileNavigationProps) {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        toggleMobileNavigation(false);
    }, [pathname, searchParams]);


    useEffect(() => {
        if (mobileNavigationVisible) {
            document.documentElement.classList.add('overflow-y-hidden');
        } else {
            document.documentElement.classList.remove('overflow-y-hidden');
        }
    }, [mobileNavigationVisible]);

    return createPortal(
        <AnimatePresence>
            {mobileNavigationVisible && (
                <motion.div
                    className="fixed z-20 max-h-screen lg:hidden"
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: 0.3, ease: 'easeOut'}}}
                    exit={{opacity: 0, transition: {duration: 0.2, ease: 'easeOut'}}}
                >

                    <MobileNavigationPanel open={true} pages={pages} navLvl={1} languages={languages} productOverviewPage={productOverviewPage} />

                </motion.div>
            )}
        </AnimatePresence>, document.body)
}
