import React from 'react';
import SearchIcon from '@/images/icons/search.svg';

interface SearchTriggerProps {
    toggleSearchOverlay: (open: boolean) => void;
}

export default function SearchTrigger({toggleSearchOverlay}: SearchTriggerProps) {
    return (
        <button
            onClick={() => toggleSearchOverlay(true)}
            type="button"
            className="bg-grey-125 h-full w-14 sm:w-16 lg:w-20 flex text-blue-100 lg:text-blue-200 hover:text-blue-100 transition border-l border-grey-175 lg:rounded-br-lg">
            <SearchIcon className="w-6 h-auto mx-auto my-auto"/>
        </button>
    )
}
