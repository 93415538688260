'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import FlyOutNavigationItem from '@/app/navigation/main/FlyOutNavigationItem';
import {useEffect, useRef, useState} from 'react';

interface FlyOutNavigationProps {
    pages: Array<INavigation>;
    open: boolean;
    navLvl: number;
}

export default function FlyOutNavigation({pages, open, navLvl}: FlyOutNavigationProps) {
    const flyOutRef = useRef<HTMLUListElement>(null);
    const [isRightAligned, setIsRightAligned] = useState<boolean>(true);

    useEffect(() => {
        const checkFlyOutPosition = () => {
            if (flyOutRef.current) {
                const rect = flyOutRef.current.getBoundingClientRect();
                const viewportWidth = window.innerWidth;

                if (rect.right > viewportWidth) {
                    setIsRightAligned(false);
                } else {
                    setIsRightAligned(true);
                }
            }
        };

        checkFlyOutPosition();
        window.addEventListener('resize', checkFlyOutPosition);

        return () => window.removeEventListener('resize', checkFlyOutPosition);
    }, []);

    return (
        <ul
            className={`absolute bg-white shadow-md p-4 z-10 min-w-80 
            ${open ? 'opacity-100' : 'invisible opacity-0'} ${navLvl === 2 ? `top-full ${!isRightAligned ? '-right-4' : '-left-4'}` : `-top-4 ${!isRightAligned ? 'right-full' : 'left-full'}`}`}
            ref={flyOutRef}
        >
            {pages.map((page, index) => (
                <FlyOutNavigationItem key={index} page={page} navLvl={navLvl}/>
            ))}
        </ul>
    )
}
