'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import React, {useRef, useState} from 'react';
import Link from 'next/link';
import ChevronRight from '@/images/icons/chevron-right.svg';
import LinkExternal from '@/images/icons/link-external.svg';
import {productCategoryPath} from '@/util/helper/path';
import MobileNavigationPanel from '@/app/navigation/mobile/MobileNavigationPanel';
import {I18nActiveLanguages} from '@/types/i18n';
import {usePathname} from 'next/navigation';

interface MobileNavigationLinkProps {
    page: INavigation;
    withThumbnail: boolean;
    navLvl: number;
    productOverviewPage?: string;
    languages: I18nActiveLanguages[];
}

export default function MobileNavigationLink(
    {
        page,
        withThumbnail,
        navLvl,
        productOverviewPage,
        languages
    }: MobileNavigationLinkProps) {
    const pathname = usePathname();

    const [openPage, setOpenPage] = useState<boolean>(false);
    const linkRef = useRef<HTMLAnchorElement>(null);
    const hasChildren = page.children && page.children.filter(p => p.visible).length > 0;

    const handleIconButtonClick = () => {
        if (hasChildren) {
            setOpenPage(true);
        } else {
            linkRef.current?.click();
        }
    }

    return (
        <>
            <div
                className={`${withThumbnail ? '' : 'min-h-12'} flex -mx-2 mb-2 justify-center content-center w-full`}
            >
                <Link
                    ref={linkRef}
                    className={`flex-grow flex ${withThumbnail ? 'py-2 px-1' : 'py-4 px-0'}`}
                    href={page.href}
                    target={page.target ?? '_self'}
                >
                    {withThumbnail && (
                        <div className="w-16 min-w-16 px-2">
                            {page.customProperties.thumbnail ? (
                                <img className="w-12 h-12 object-cover rounded" src={page.customProperties.thumbnail}
                                     alt={page.customProperties.title ?? page.label}/>
                            ) : (
                                <div className="w-12 h-12 rounded-md bg-grey-175"></div>
                            )}
                        </div>
                    )}
                    <div className={`flex-grow flex ${withThumbnail ? 'px-2' : 'px-6'}`}>
                        <span
                            className={`${new RegExp((productOverviewPage === page.href && navLvl > 1) ? `^${page.href}$` : `^${page.href}.*$`).test(pathname) ? 'text-blue' : 'text-blue-200'} focus:text-blue hover:text-blue active:text-blue font-bold inline-block my-auto transition`}>{page.label}</span>
                    </div>
                </Link>

                {hasChildren || page.target === '_blank' ? (
                    <div className="w-12 min-w-12 px-2">
                        <button
                            className={`${!withThumbnail ? '-mt-1' : 'mt-0.5'} p-4`}
                            onClick={handleIconButtonClick}
                        >
                            {hasChildren ? (
                                <ChevronRight className="w-6 inline text-blue-100"/>
                            ) : page.target === '_blank' && (
                                <LinkExternal className="w-6 inline text-blue-100"/>
                            )}
                        </button>
                    </div>
                ) : <></>}
            </div>

            {hasChildren && (
                <MobileNavigationPanel
                    open={openPage}
                    pages={page.children ? page.children.filter(p => p.visible) : []}
                    navLvl={navLvl + 1}
                    onClose={() => setOpenPage(false)}
                    languages={languages}
                    productOverviewPage={productOverviewPage}
                />
            )}
        </>
    )
}
