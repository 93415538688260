import {INavigation} from '@/app/navigation/Navigation.types';
import MainNavigationItem from '@/app/navigation/main/MainNavigationItem';
import React from 'react';
import StoreWrapper from '@/app/components/cms/StoreWrapper';
import {getDocumentProperties} from '@/api/api';
import {DocumentProperty} from '@/types/pimcore';

interface MainNavigationProps {
    pages: Array<INavigation>;
    productRequestPage: DocumentProperty | undefined;
    productOverviewPage: DocumentProperty | undefined;
    toggleBackdropVisible: (open: boolean) => void;
}

export default function MainNavigation({pages, productRequestPage, productOverviewPage, toggleBackdropVisible}: MainNavigationProps) {
    return (
        <nav>
            <ul className="flex">
                {pages.map((page, index) => (
                        <MainNavigationItem
                            key={index}
                            page={page}
                            toggleBackdropVisible={toggleBackdropVisible}
                            productRequestPage={productRequestPage?.element?.fullPath}
                            productOverviewPage={productOverviewPage?.element?.fullPath}
                        />
                    ),
                )}
            </ul>
        </nav>
    )
}
