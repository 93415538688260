import {AnimatePresence, motion} from 'framer-motion';
import {useEffect} from 'react';
import { usePathname } from 'next/navigation';

interface BackdropProps {
    isOpen: boolean;
    megaMenuVisible: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

export default function Backdrop({isOpen, megaMenuVisible, setIsOpen}: BackdropProps) {
    const pathname = usePathname();

    useEffect(() => {
        setIsOpen(false);
    }, [pathname]);

    useEffect(() => {
        if (isOpen && !megaMenuVisible) {
            document.documentElement.classList.add('overflow-y-hidden');
        } else {
            document.documentElement.classList.remove('overflow-y-hidden');
        }
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed z-20"
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: 0.3, ease: 'easeOut'}}}
                    exit={{opacity: 0, transition: {duration: 0.2, ease: 'easeOut'}}}
                >
                    <div
                        className={`absolute left-0 top-0 w-screen h-screen bg-gradient-to-r from-blue-200 to-blue-125 opacity-[0.88] pointer-events-none`}>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>

    )
}
