'use client';

import Link from 'next/link';
import LogoRathgeber from '@/images/logo.svg';
import LogoSmartTec from '@/images/logo-smart-tec.svg';
import MainNavigation from '@/app/navigation/main/MainNavigation';
import PhoneMailIcon from '@/images/icons/phone-mail.svg'
import Search from '@/app/layout/search/Search';
import SearchTrigger from '@/app/layout/search/SearchTrigger';
import React, {useState} from 'react';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import MobileNavigationTrigger from '@/app/navigation/mobile/MobileNavigationTrigger';
import {DocumentProperty} from '@/types/pimcore';
import {INavigation} from '@/app/navigation/Navigation.types';
import MobileNavigation from '@/app/navigation/mobile/MobileNavigation';
import {I18nActiveLanguages} from '@/types/i18n';
import useTranslations from '@/hooks/translations';
import Backdrop from '@/app/layout/Backdrop';

interface HeaderProps {
    withStartAnimation: boolean;
    pages: INavigation[];
    productRequestPage: DocumentProperty | undefined;
    productOverviewPage: DocumentProperty | undefined;
    contactPagePath: string | null;
    activeLanguages: I18nActiveLanguages[];
    theme: string;
}

export default function Header({
                                   withStartAnimation,
                                   pages,
                                   activeLanguages,
                                   theme,
                                   productOverviewPage,
                                   productRequestPage,
                                   contactPagePath,
                               }: HeaderProps) {
    const {locale} = useTranslations();

    const [searchOverlayVisible, setSearchOverlayVisible] = useState<boolean>(false);
    const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
    const [megaMenuVisible, setMegaMenuVisible] = useState<boolean>(false);
    const [mobileNavigationVisible, setMobileNavigationVisible] = useState<boolean>(false);

    const toggleSearchOverlay = (open: boolean) => {
        setSearchOverlayVisible(open);
        setBackdropVisible(open);
        setMegaMenuVisible(false);
        setMobileNavigationVisible(false);
    }

    const toggleBackdropVisible = (open: boolean) => {
        setMegaMenuVisible(open);
        setBackdropVisible(open);
    }

    const toggleMobileNavigation = (open: boolean) => {
        setMobileNavigationVisible(open);
        setMegaMenuVisible(false);
        setBackdropVisible(false);
        setSearchOverlayVisible(false);
    }

    return (
        <>
            <header id={'page-header'} className="fixed w-full top-0 z-30">
                <div className="container relative">
                    <ClientOnly>
                        <Search searchOverlayVisible={searchOverlayVisible}
                                mobileNavigationVisible={mobileNavigationVisible}
                                toggleMobileNavigation={toggleMobileNavigation}
                                toggleSearchOverlay={toggleSearchOverlay}/>
                    </ClientOnly>
                    <div
                        className={`bg-white shadow-[0_2px_8px_0_rgba(0,0,0,0.16)] rounded-b-lg ${withStartAnimation && 'animate-start-slide-down'}`}>
                        <div className="h-14 sm:h-16 lg:h-20 flex justify-end items-center relative">

                            <div className="ml-0 mr-auto h-full flex">
                                <Link href={`/${locale}`} className="h-full pl-2 pr-2 ty:pl-6 lg:pl-10 flex">

                                    {theme === 'smart-tec' && (
                                        <LogoSmartTec className="text-blue-200 h-6 sm:h-8 w-auto max-w-full my-auto"/>
                                    )}

                                    {theme === 'rathgeber' && (
                                        <LogoRathgeber className="text-blue-200 h-3.5 sm:h-5 lg:h-6 w-auto max-w-full my-auto"/>
                                    )}
                                </Link>
                            </div>
                            <div className="hidden lg:block mr-4">
                                <MainNavigation productOverviewPage={productOverviewPage}
                                                productRequestPage={productRequestPage}
                                                toggleBackdropVisible={toggleBackdropVisible}
                                                pages={pages.filter(page => page.visible)}/>
                            </div>

                            <div className="flex h-full">
                                {contactPagePath && (
                                    <Link href={contactPagePath}
                                          className="bg-grey-125 h-full w-14 sm:w-16 lg:w-20 flex text-blue-100 lg:text-blue-200 hover:text-blue-100 transition">
                                        <PhoneMailIcon className="w-6 h-auto mx-auto my-auto"/>
                                    </Link>
                                )}
                                <div className="w-14 sm:w-16 lg:w-20">
                                    <SearchTrigger toggleSearchOverlay={toggleSearchOverlay}/>
                                </div>
                            </div>

                            <div className="block lg:hidden h-full">
                                <MobileNavigationTrigger toggleMobileNavigation={toggleMobileNavigation}
                                                         mobileNavigationVisible={mobileNavigationVisible}/>
                            </div>
                        </div>
                    </div>

                </div>
            </header>
            <ClientOnly>
                <MobileNavigation pages={pages} languages={activeLanguages}
                                  toggleMobileNavigation={toggleMobileNavigation}
                                  mobileNavigationVisible={mobileNavigationVisible}
                                  productOverviewPage={productOverviewPage?.element?.fullPath}/>
                <Backdrop isOpen={backdropVisible}
                          megaMenuVisible={megaMenuVisible}
                          setIsOpen={toggleBackdropVisible}/>
            </ClientOnly>
        </>
    )
}
